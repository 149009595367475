import React from 'react';
import { useNavigate } from 'react-router-dom';
import kieteeLogo from '../../assets/kieteeLogo.png'
import './Reset.css'
import CustomInputField from '../../components/CustomField/CustomInputField ';
import ContinueButton from '../../components/ContinueButton/ContinueButton';

const Reset = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/reset-password'); 
  };

  return (
    <div className='getStarted'>
      <img src={kieteeLogo} alt="kieteeLogo.png" />
      <div className='titleGuide'>
        <span className='titleHeader'>Let's help you recover your login details</span>
        <span className='guideText'>We’ll help you to recover your password we have sent an email verification</span>
      </div>
      <div className='inputsField'>
      <CustomInputField
           label="Email Address" 
           name="email" 
           placeholder="Enter your email" 
           type="email" />
      <ContinueButton onClick={handleClick}>Confirm</ContinueButton>
      </div>
    </div>
  )
}

export default Reset