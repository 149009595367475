import React, { useEffect, useState } from 'react';
import StatsCard from '../../components/StatsCard/StatsCard';
import { HomeOutlined, UserOutlined, BoxPlotOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import './Dashboard.css';
import CustomPieChart from '../../components/PieChart/PieChart';
import Analytics from '../../components/Analytics/Analytics';
import axios from 'axios';

const Dashboard = () => {
  const [stats, setStats] = useState({
    employees: 0,
    customers: 0,
    invoices: 0,
    orders: 0,
  });

  const fetchStats = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Replace API endpoints with your real ones
      const [invoicesResponse, customersResponse, employeesResponse, ordersResponse] = await Promise.all([
        axios.get('https://crm.deluxecrm.co/api/users/employees/count', { headers }),
        axios.get('https://crm.deluxecrm.co/api/customer/count', { headers }),
        axios.get('https://crm.deluxecrm.co/api/invoices/count', { headers }),
        axios.get('https://crm.deluxecrm.co/api/orders/count', { headers }),
      ]);

      setStats({
        employees: employeesResponse.data.count,
        customers: customersResponse.data.count,
        invoices: invoicesResponse.data.count,
        orders: ordersResponse.data.count,
      });
    } catch (error) {
      console.error('Failed to fetch stats', error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      <div className="stats-cards-container">
        <StatsCard 
          title="Total Sales" 
          value={stats.totalSales} 
          icon={<HomeOutlined />} 
        />
        <StatsCard 
          title="New Customers" 
          value={stats.newCustomers} 
          icon={<UserOutlined />} 
        />
        <StatsCard 
          title="Items Sold" 
          value={stats.itemsSold} 
          icon={<BoxPlotOutlined />} 
        />
        <StatsCard 
          title="Orders Today" 
          value={stats.ordersToday} 
          icon={<ShoppingCartOutlined />} 
        />
      </div>
      <div className="charts-container">
        <CustomPieChart />
        <Analytics />
      </div>
    </div>
  );
};

export default Dashboard;
