import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined, UserOutlined, ShopOutlined, ShoppingCartOutlined, FileTextOutlined, TruckOutlined, FileDoneOutlined, AuditOutlined, SettingOutlined } from '@ant-design/icons';
import './Sidebar.css';

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className='sidebar'>
      <nav className='sidebar-nav'>
        <ul>
          <li>
            <Link to='/dashboard' className={currentPath === '/dashboard' ? 'active' : ''}>
              <HomeOutlined /> <span className='sidebar-label'>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to='/customer' className={currentPath === '/customer' ? 'active' : ''}>
              <UserOutlined /> <span className='sidebar-label'>Customer</span>
            </Link>
          </li>
          <li>
            <Link to='/items' className={currentPath === '/items' ? 'active' : ''}>
              <ShopOutlined /> <span className='sidebar-label'>Items</span>
            </Link>
          </li>
          <li>
            <Link to='/sales-orders' className={currentPath === '/sales-orders' ? 'active' : ''}>
              <ShoppingCartOutlined /> <span className='sidebar-label'>Sales Orders</span>
            </Link>
          </li>
          <li>
            <Link to='/quotations' className={currentPath === '/quotations' ? 'active' : ''}>
              <FileTextOutlined /> <span className='sidebar-label'>Quotations</span>
            </Link>
          </li>
          <li>
            <Link to='/invoices' className={currentPath === '/invoices' ? 'active' : ''}>
              <FileDoneOutlined /> <span className='sidebar-label'>Invoices</span>
            </Link>
          </li>
          <li>
            <Link to='/delivery-notes' className={currentPath === '/delivery-notes' ? 'active' : ''}>
              <TruckOutlined /> <span className='sidebar-label'>Delivery Notes</span>
            </Link>
          </li>
          <li>
            <Link to='/reports' className={currentPath === '/reports' ? 'active' : ''}>
              <AuditOutlined  /> <span className='sidebar-label'>Reports</span>
            </Link>
          </li>
          <li>
            <Link to='/settings' className={currentPath === '/settings' ? 'active' : ''}>
              <SettingOutlined /> <span className='sidebar-label'>Settings</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
