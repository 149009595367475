import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'; // Importing the plus icon

const AddDataButton = ({ onClick }) => {
  return (
    <Button 
      type="primary" 
      icon={<PlusOutlined />} // Adding the plus icon
      onClick={onClick} // Action to be triggered on button click
      style={{ borderRadius: '5px', fontWeight: 'bold', width: '150px', height: '40px' }}
    >
      Add Data
    </Button>
  );
};

export default AddDataButton;
