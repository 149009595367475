import React, { useEffect, useState } from 'react';
import { Typography, Spin, message } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import kieteeLogo from '../../assets/kieteeLogo.png';
import './QuotationDetails.css';

const { Title, Text } = Typography;

export default function QuotationDetails() {
  const { id } = useParams(); // Fetch order ID from route
  const [quotationDetails, setQuotationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuotationDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://crm.deluxecrm.co/api/quotations/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setQuotationDetails(response.data); // Update quotation details in state
      } catch (error) {
        setError(error.message);
        message.error('Failed to fetch quotation details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchQuotationDetails(); // Fetch details if id is available
    }
  }, [id]);

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Destructure quotation details for easier access
  const {
    company,
    items,
    issueDate,
    subtotal,
    tax,
    totalAmount,
    notes,
    customer,
  } = quotationDetails;

  return (
    <div className='quotation-template'>
      <div className='header-container'>
        <div className='line' />
        <div className='title-box'>
          <h1 className='title'>Quotation</h1>
        </div>
      </div>
      <div className='date-logo'>
        <div className="quotation-header">
          <Text strong className='qots-text'>Quotation Date: </Text>
          <Text>{new Date(issueDate).toLocaleDateString()}</Text><br />
        </div>
        <img src={kieteeLogo} alt="Kietee Logo" className='logo-display' />
      </div>
      
      <div className='sender-receiver-container'>
        <div className='to-rece'>
          <span className='to-text'>To:</span>
          <span className='to-text'>Tel:</span>
        </div>
        <div className='line-divide' />
        <div className='container-layout'>
          <div className="quotation-section">
            <Text strong>Name: </Text>
            <Text>{customer.name}</Text><br />
            <Text strong>Email: </Text>
            <Text>{customer.email}</Text><br />
            <Text strong>Phone: </Text>
            <Text>{customer.phone}</Text><br />
            <Text strong>Address: </Text>
            <Text>{customer.address}</Text><br />
            <Text strong>Company Name: </Text>
            <Text>{customer.companyName}</Text><br />
            <Text strong>TIN: </Text>
            <Text>{customer.customerTIN}</Text><br />
          </div>

          <div className="line-vertical" /> {/* Vertical line between sections */}

          <div className="quotation-section2">
            <Text strong>Company Name: </Text>
            <Text>{company.companyName}</Text><br />
            <Text strong>Address: </Text>
            <Text>{company.address}</Text><br />
            <Text strong>Phone Numbers: </Text>
            <Text>{company.phoneNumbers.join(', ')}</Text><br />
            <Text strong>Email: </Text>
            <Text>{company.email}</Text><br />
            <Text strong>TIN: </Text>
            <Text>{company.TIN}</Text><br />
            <Text strong>PO Box: </Text>
            <Text>{company.poBox}</Text><br />
          </div>
        </div>
      </div>

      <div className="quotation-section">
        <Title level={4}>Items</Title>
        <table className="quotation-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Description</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item._id}>
                <td>{item.item.name}</td>
                <td>{item.item.description}</td>
                <td>{item.item.price}</td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="quotation-summary">
        <Title level={4}>Financial Summary</Title>
        <div className="summary">
          <Text strong>Subtotal: </Text>
          <Text>{subtotal}</Text>
        </div>
        <div className="summary">
          <Text strong>Tax: </Text>
          <Text>{tax}</Text>
        </div>
        <div className="summary">
          <Text strong>Total Amount: </Text>
          <Text>{totalAmount}</Text>
        </div>
      </div>

      <div className='footer'>
        <div className="signature-section">
          <div className="signature-info">
            <Text strong>Received By:</Text>
            <div className="signature-area">
              <div className="sign" />
              <Text strong>Date:</Text>
              <Text>_________</Text> {/* Placeholder for the date */}
            </div>
            <Text className="disclaimer">
              Please ensure you check your quotation.
            </Text>
          </div>

          <div className="signature-info">
            <Text strong>For Deluxe Promotions use:</Text>
            <Text>Prepared By: Saidi Lusambi</Text>
            <Text>Delivered By: Saidi Lusambi</Text>
            <Text>Phone No: 0657 639 622</Text>
            <div className="sign" />
          </div>
        </div>

        <div className="quotation-payments">
          <Title level={4}>Payments</Title>
          <Text strong>Account Name: </Text>
          <Text>{company.payments[0].accountName}</Text><br />
          <Text strong>Account Number: </Text>
          <Text>{company.payments[0].accountNumber}</Text><br />
          <Text strong>Bank Name: </Text>
          <Text>{company.payments[0].bankName}</Text><br />
          <Text strong>Swift Code: </Text>
          <Text>{company.payments[0].swiftCode}<br /></Text>
          <Text strong>Mobile Money: </Text>
          <Text>{company.mobileMoneyPayments[0].accountName} (Merchant No: {company.mobileMoneyPayments[0].merchantNumber})</Text><br />
        </div>

        {notes && (
          <div className="quotation-notes">
            <Title level={4}>Notes</Title>
            <Text>{notes}</Text><br />
          </div>
        )}
      </div>
    </div>
  );
}
