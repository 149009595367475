import React from 'react';
import StatsCard from '../../components/StatsCard/StatsCard';
import { HomeOutlined, UserOutlined, BoxPlotOutlined, ShoppingCartOutlined, } from '@ant-design/icons';
import './Reports.css';
import CustomPieChart from '../../components/PieChart/PieChart';
import Analytics from '../../components/Analytics/Analytics';

const Reports = () => {
  return (
    <div className="dashboard-container">
      <h2>Reports</h2>
      <div className="stats-cards-container">
        <StatsCard 
          title="Total Sales" 
          value="$1,234,567" 
          icon={<HomeOutlined />} 
        />
        <StatsCard 
          title="New Customers" 
          value="120" 
          icon={<UserOutlined />} 
        />
        <StatsCard 
          title="Items Sold" 
          value="1,000" 
          icon={<BoxPlotOutlined />} 
        />
        <StatsCard 
          title="Orders Today" 
          value="56" 
          icon={<ShoppingCartOutlined />} 
        />
      </div>
      <div className="charts-container">
        <CustomPieChart />
        <Analytics />
      </div>
    </div>
  );
};

export default Reports;
