import React from 'react';
import './CustomerCard.css';

function CustomerCard({ customer }) {
  if (!customer) return null; // Handle the case where customer data is not available yet

  return (
    <div className='card-container'>
      <div className='stat-cards'>
        <div className='customer-details-preview'>
          <div className='initials-cycle'>
            <span className='initials-letter'>
              {customer.name ? customer.name[0].toUpperCase() : ''}
            </span>
          </div>
          <h3 className='customer-name'>{customer.name}</h3>
          <div className='details'>
            <div className='details-colunms'>
              <span className='email-title'>Email:</span>
              <span className='email-details'>{customer.email}</span>
            </div>
            <div className='details-colunms'>
              <span className='email-title'>Phone:</span>
              <span className='email-details'>{customer.phone}</span>
            </div>
            <div className='details-colunms'>
              <span className='email-title'>Address:</span>
              <span className='email-details'>{customer.address}</span>
            </div>
            <div className='details-colunms'>
              <span className='email-title'>TIN:</span>
              <span className='email-details'>{customer.customerTIN}</span>
            </div>
            <div className='details-colunms'>
              <span className='email-title'>Tax Type:</span>
              <span className='email-details'>{customer.taxType}</span>
            </div>
            <div className='details-colunms'>
              <span className='email-title'>Type:</span>
              <span className='email-details'>{customer.type}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerCard;
