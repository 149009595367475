import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Spin, message, Input, Select, Modal } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Invoice.css';
import Box from '../../assets/Box.png'; // Placeholder image for empty state
import { PlusOutlined } from '@ant-design/icons'; // Ant design icon
import AddInvoiceForm from '../../components/AddInvoiceForm/AddInvoiceForm'; // Modal form for adding sales order

const { Title } = Typography;
const { Option } = Select;

const columns = [
  { title: 'ID', dataIndex: 'sequence', key: 'sequence', width: 90 },
  { title: 'Invoice Number', dataIndex: 'invoiceNumber', key: 'invoiceNumber', width: 150 },
  { title: 'Customer Name', dataIndex: 'customerName', key: 'customerName', width: 150 },
  { title: 'Issue Date', dataIndex: 'issueDate', key: 'issueDate', width: 150, render: (date) => new Date(date).toLocaleDateString() },
  { title: 'Due Date', dataIndex: 'dueDate', key: 'dueDate', width: 150, render: (date) => new Date(date).toLocaleDateString() },
  { title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount', width: 150, render: (amount) => `$${amount.toFixed(2)}` },
  { title: 'Status', dataIndex: 'status', key: 'status', width: 150 },
];

const Invoice = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate


  const fetchInvoiceData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://crm.deluxecrm.co/api/invoices', {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const transformedOrders = response.data.map((invoice, index) => ({
        id: invoice._id,
        sequence: index + 1,
        customerName: invoice.customer?.name,
        issueDate: invoice.issueDate,
        dueDate: invoice.dueDate,
        totalAmount: invoice.totalAmount,
        status: invoice.status,
        invoiceNumber: invoice.invoiceNumber,
      }));
  
      setOrders(transformedOrders);
      setFilteredOrders(transformedOrders);
    } catch (error) {
      setError(error.message);
      message.error('Failed to fetch invoice data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  // Handle search functionality
  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = orders.filter((order) =>
      order.customerName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  // Handle filter functionality
  const handleFilter = (value) => {
    setFilterStatus(value);
    const filtered = value ? orders.filter((order) => order.status === value) : orders;
    setFilteredOrders(filtered);
  };

  // Show modal for adding a sales order
  const handleAddData = () => {
    setIsModalVisible(true);
  };

  const handleRowClick = (id) => {
    navigate(`/invoices-details/${id}`); // Navigate to ItemDetails with item id
  };

  // Close modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleInvoiceAdded = () => {
    fetchInvoiceData(); // Refresh the order list after adding
    handleCloseModal(); // Close the modal after adding
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="sales-order-container">
      <div className="header-action">
        <Title level={3} className="sales-order-title">Invoice</Title>
        {orders.length > 0 && (
          <div className="add-container">
            <Button className="export-button" type="secondary" onClick={() => console.log('Export CSV')}>Export CSV</Button>
            <Button className="add-button" type="primary" icon={<PlusOutlined />} onClick={handleAddData}>Add Invoice</Button>
          </div>
        )}
      </div>

      {orders.length === 0 ? (
        <div className="empty-field">
        <img src={Box} alt="empty box" className="box" />
          <span className="upper-text">It looks like you don’t have any invoices</span>
          <span className="lower-text">Create a new one to get started</span>
          <Button type="primary" onClick={handleAddData}>Add Invoice</Button>
        </div>
      ) : (
        <div className="table-container">
          <div className="search-filter-container">
            <Input
              placeholder="Search by customer name"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 200 }}
            />
            <span className="filter">Filter:</span>
            <Select
              placeholder="Filter by status"
              value={filterStatus}
              onChange={handleFilter}
              style={{ width: 150 }}
              allowClear
            >
              <Option value="Pending">Pending</Option>
              <Option value="Completed">Completed</Option>
              <Option value="Cancelled">Cancelled</Option>
            </Select>
          </div>

          <Table
            columns={columns}
            dataSource={filteredOrders}
            pagination={{ pageSize: 10 }}
            rowKey="id"
            className="sales-order-table"
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
              style: { cursor: 'pointer' } // Pointer cursor on hover
            })}
          />
        </div>
      )}

      {/* Add Sales Order Modal */}
      <Modal
        title="Add Invoice"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
        width={800}
      >
        <AddInvoiceForm onInvoiceAdded={handleInvoiceAdded} />
      </Modal>
    </div>
  );
};

export default Invoice;
