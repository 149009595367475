import React from 'react';
import { Form, Input } from 'antd';
import './PasswordInputField.css'; 

const PasswordInputField = ({ value, onChange, onResetPasswordClick, onKeyDown }) => {
  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
    >
      <Form.Item
        label="Password"
        name="password"
        className="custom-form-item"
      >
        <div className="password-container">
          <Input
            placeholder="Enter your password"
            type="password"
            className="custom-input"
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <span className="reset-password" onClick={onResetPasswordClick}>
            Reset Password
          </span>
        </div>
      </Form.Item>
    </Form>
  );
};

export default PasswordInputField;
