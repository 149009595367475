import React from 'react';
import { Button } from 'antd';
import './ContinueButton.css';

const ContinueButton = ({ onClick, children }) => {
  return (
    <Button className="continue-button" onClick={onClick}>
      {children || 'Continue'}
    </Button>
  );
};

export default ContinueButton;
