import React, { useState } from 'react';
import { Modal, Input, Form, Button, message, Row, Col, Select } from 'antd';
import axios from 'axios';
import './AddItemForm.css'; // Assuming you have consistent styling similar to AddCustomerForm

const { Option } = Select;

const AddItemForm = ({ visible, onClose, onItemAdded }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleAddItem = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://crm.deluxecrm.co/api/item', values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('Item added successfully');
      form.resetFields();
      onItemAdded(); // Callback to refresh the item list
      onClose();
    } catch (error) {
      message.error('Failed to add item');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="New Item"
      onCancel={onClose}
      footer={null}
      className="add-item-modal"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddItem}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Item Name"
              rules={[{ required: true, message: 'Please enter item name' }]}
            >
              <Input placeholder="Item Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="price"
              label="Price"
              rules={[{ required: true, message: 'Please enter item price' }]}
            >
              <Input type="number" placeholder="Item Price" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: 'Please select a category' }]}
            >
              <Select placeholder="Select Category">
                <Option value="Electronics">Electronics</Option>
                <Option value="Furniture">Furniture</Option>
                <Option value="Clothing">Clothing</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[{ required: true, message: 'Please enter item quantity' }]}
            >
              <Input type="number" placeholder="Quantity" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="itemType"
              label="Item Type"
              rules={[{ required: true, message: 'Please select item type' }]}
            >
              <Select placeholder="Select Item Type">
                <Option value="Goods">Goods</Option>
                <Option value="Services">Services</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="description"
              label="Description"
            >
              <Input.TextArea rows={2} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" loading={loading}>
          Add Item
        </Button>
      </Form>
    </Modal>
  );
};

export default AddItemForm;
