import React, { useEffect, useState } from 'react';
import './Header.css';
import kieteeLogo from '../../assets/kieteeLogo.png';
import { SearchOutlined, MoreOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useAuth } from '../../config/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const Header = () => {
  const [username, setUsername] = useState('');
  const { userId } = useAuth();
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await axios.get(`https://crm.deluxecrm.co/api/users/employees/${userId}`);
        setUsername(response.data.name); // Assuming the response has a name field
        console.log(response)
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    if (userId) {
      fetchUsername();
    }
  }, [userId]);

  return (
    <div className='container'>
      {/* Left Section: Logo */}
      <div className='leftSection'>
        <img src={kieteeLogo} alt="Kietee Logo" className='logo' />
      </div>

      {/* Middle Section: Search Bar */}
      <div className='middleSection'>
        <div className='search-box'>
          <Input
            className="search-input"
            placeholder="Search"
            prefix={<SearchOutlined className='search-icon' />} 
          />
        </div>
      </div>

      {/* Right Section: User Info */}
      <div className='rightSection'>
        <div className='initials-cycle'>
          <span className='initials-letter'>JD</span>
        </div>
        <div className='userInfo'>
          <span className='user-name'>{username}</span>
          <span className='company-name'>Sales Person</span>
        </div>
        <MoreOutlined className='more-icon' />
      </div>
    </div>
  );
}

export default Header;
