// src/layout/MainLayout.js

import React from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import './MainLayout.css';

const MainLayout = ({ children }) => {
  return (
    <div className='main-layout'>
      <Header />
      <div className='layout-body'>
        <Sidebar />
        <div className='main-content'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
