import React from 'react';
import { Card, Typography } from 'antd';
import './ItemCard.css';

const { Title, Text } = Typography;

const ItemCard = ({ item }) => {
  if (!item) return null; // Handle cases where item is null or undefined

  return (
    <Card className="item-card">
      <Title level={4}>{item.name}</Title>
      <Text><strong>ID:</strong> {item.sequence}</Text><br />
      <Text><strong>Description:</strong> {item.description}</Text><br />
      <Text><strong>Price:</strong> ${item.price}</Text><br />
      <Text><strong>Stock:</strong> {item.itemType}</Text><br />
      <Text><strong>Supplier:</strong> {item.createdAt}</Text>
    </Card>
  );
};

export default ItemCard;
