import React, { useState } from 'react';
import loginPic from '../../assets/loginPic.png';
import './Login.css';
import CustomInputField from '../../components/CustomField/CustomInputField ';
import ContinueButton from '../../components/ContinueButton/ContinueButton';
import { useNavigate } from 'react-router-dom';
import PasswordInputField from '../../components/PasswordInputField/PasswordInputField';
import { useAuth } from '../../config/AuthContext';
import axios from 'axios';

const Login = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login } = useAuth();

  const navigateToResetPassword = () => {
    navigate('/reset');
  };

  const handleSubmit = async () => {

    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }
    setError("");
    try {
      const response = await axios.post('https://crm.deluxecrm.co/api/users/login', { email, password });
      const userId = response.data._id; 
      const token = response.data.token;

      login(userId, token);
      navigate('/dashboard');

      if (onLoginSuccess) onLoginSuccess(userId);
    } catch (err) {
      if (err.response) {
        console.error('Error Response:', err.response);
        setError(err.response.data.message || 'Login failed.');
      } else {
        setError("An unexpected error occurred. Please try again.");
        console.error('Error:', err);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className='login-container'>
      <div className='image-container'>
        <img src={loginPic} alt="loginPic.png" />
      </div>
      <div className='form-container'>
        <span className='title'>Login</span>
        <div className='inputsField'>
          <CustomInputField
            label="Email Address"
            name="email"
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <PasswordInputField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onResetPasswordClick={navigateToResetPassword}
            onKeyDown={handleKeyDown}
          />
          <ContinueButton onClick={handleSubmit}>Login</ContinueButton>
        </div>
      </div>
    </div>
  );
};

export default Login;
