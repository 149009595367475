import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Spin, message, Input, Select } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Items.css';
import Box from '../../assets/Box.png';
import AddDataButton from '../../components/AddDataButton/AddDataButton';
import { PlusOutlined } from '@ant-design/icons';
import AddItemForm from '../../components/AddItemForm/AddItemForm';

const { Title } = Typography;
const { Option } = Select;

const columns = [
  { title: 'ID', dataIndex: 'sequence', key: 'sequence', width: 90 },
  { title: 'Name', dataIndex: 'name', key: 'name', width: 150 },
  { title: 'Description', dataIndex: 'description', key: 'description', width: 150 },
  { title: 'Price', dataIndex: 'price', key: 'price', width: 100 },
  { title: 'Stock', dataIndex: 'itemType', key: 'itemType', width: 100 },
  { title: 'Supplier', dataIndex: 'createdAt', key: 'createdAt', width: 200 },
];

const Items = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  const fetchItemData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://crm.deluxecrm.co/api/item', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const transformedRows = response.data.map((item, index) => ({
        id: item._id,
        sequence: index + 1,
        ...item,
      }));
      setRows(transformedRows);
      setFilteredRows(transformedRows);
    } catch (error) {
      setError(error.message);
      message.error('Failed to fetch item data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = rows.filter((row) =>
      row.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRows(filtered);
  };

  const handleFilter = (value) => {
    setFilterCategory(value);
    const filtered = value
      ? rows.filter((row) => row.category === value)
      : rows;
    setFilteredRows(filtered);
  };

  const handleAddData = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleItemAdded = () => {
    fetchItemData();
  };

  const handleRowClick = (id) => {
    navigate(`/items-details/${id}`); // Navigate to ItemDetails with item id
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="item-container">
      <div className="header-action">
        <Title level={3} className="item-title">Items</Title>
        {rows.length > 0 && (
          <div className="add-container">
            <Button className="export-button" type="secondary" onClick={handleAddData}>Export CSV</Button>
            <Button className="add-button" type="primary" icon={<PlusOutlined />} onClick={handleAddData}>Add Item</Button>
          </div>
        )}
      </div>

      {rows.length === 0 ? (
        <div className="empty-field">
          <img src={Box} alt="empty box" className="box" />
          <span className="upper-text">It looks like you don’t have any data</span>
          <span className="lower-text">Fortunately, it’s easy to create a new one</span>
          <AddDataButton onClick={handleAddData} className="add-button" />
        </div>
      ) : (
        <div className="table-container">
          <div className="search-filter-container">
            <Input
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 200 }}
            />
            <span className='filter'>Filter:</span>
            <Select
              placeholder="Filter by category"
              value={filterCategory}
              onChange={handleFilter}
              style={{ width: 150 }}
              allowClear
            >
              <Option value="Electronics">Electronics</Option>
              <Option value="Clothing">Clothing</Option>
              <Option value="Accessories">Accessories</Option>
            </Select>
          </div>

          <Table
            columns={columns}
            dataSource={filteredRows}
            pagination={{ pageSize: 10 }}
            rowKey="id"
            className="item-table"
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
              style: { cursor: 'pointer' } // Pointer cursor on hover
            })}
          />
        </div>
      )}

      <AddItemForm   
        visible={isModalVisible} 
        onClose={handleCloseModal} 
        onItemAdded={handleItemAdded}
      />
    </div>
  );
};

export default Items;
