import React from 'react';
import { Form, Input } from 'antd';
import './CustomInputField.css';

const CustomInputField = ({ label, name, placeholder, type = "text", value, onChange, onKeyDown }) => {
  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
    >
      <Form.Item
        label={label}
        name={name}
        className="custom-form-item"
      >
        <Input
          type={type}
          placeholder={placeholder}
          className="custom-input"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </Form.Item>
    </Form>
  );
};

export default CustomInputField;
