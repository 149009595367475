import React, { useEffect, useState } from 'react';
import { Typography, Card, Descriptions, Row, Col, Divider, Spin, message } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './SalesDetails.css';

const { Title, Text } = Typography;

export default function SaleDetails() {
  const { id } = useParams(); // Fetch order ID from route
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://crm.deluxecrm.co/api/orders/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrderDetails(response.data); // Update order details in state
      } catch (error) {
        setError(error.message);
        message.error('Failed to fetch sales order details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails(); // Fetch details if orderId is available
    }
  }, [id]);

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const { salesOrderNumber, customer, company, items, issueDate, deliveryDate, totalAmount, subtotal, tax, status } = orderDetails;

  return (
    <div className='sales-details-page'>
      <div className="header-action">
        <Title level={3} className="sales-title">Sales Order: {salesOrderNumber}</Title>
      </div>


      {/* Customer & Company Info */}
      <Row gutter={16}>
        {/* Customer Info */}
        <Col xs={24} md={12}>
          <Card className="info-card" title="Customer Details">
            <Descriptions column={1} size="small">
              <Descriptions.Item label="Name">{customer.name}</Descriptions.Item>
              <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
              <Descriptions.Item label="Phone">{customer.phone}</Descriptions.Item>
              <Descriptions.Item label="Company">{customer.companyName}</Descriptions.Item>
              <Descriptions.Item label="Address">{customer.address}</Descriptions.Item>
              <Descriptions.Item label="TIN">{customer.customerTIN}</Descriptions.Item>
              <Descriptions.Item label="VRN">{customer.customerVRN}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        {/* Company Info */}
        <Col xs={24} md={12}>
          <Card className="info-card" title="Company Details">
            <Descriptions column={1} size="small">
              <Descriptions.Item label="Company">{company.companyName}</Descriptions.Item>
              <Descriptions.Item label="Phone">{company.phoneNumbers.join(', ')}</Descriptions.Item>
              <Descriptions.Item label="Email">{company.email}</Descriptions.Item>
              <Descriptions.Item label="Address">{company.address}</Descriptions.Item>
              <Descriptions.Item label="TIN">{company.TIN}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>

      <Divider />

      {/* Order Info */}
      <Card className="info-card" title="Order Details">
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Issue Date">{new Date(issueDate).toLocaleDateString()}</Descriptions.Item>
          <Descriptions.Item label="Delivery Date">{new Date(deliveryDate).toLocaleDateString()}</Descriptions.Item>
          <Descriptions.Item label="Subtotal">{subtotal.toLocaleString()} TZS</Descriptions.Item>
          <Descriptions.Item label="Tax">{tax.toLocaleString()} TZS</Descriptions.Item>
          <Descriptions.Item label="Total Amount">{totalAmount.toLocaleString()} TZS</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Text type={status === 'Pending' ? 'warning' : 'success'}>{status}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Divider />

      {/* Items List */}
      <Card className="info-card" title="Items Ordered">
        <Descriptions column={1} size="small" bordered>
          {items.map((itemEntry, index) => (
            <Descriptions.Item label={`Item ${index + 1}`} key={itemEntry._id}>
              <strong>{itemEntry.item.name}</strong> - {itemEntry.quantity} x {itemEntry.item.price.toLocaleString()} TZS
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>
    </div>
  );
}
