import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import './EditItemCard.css';

const EditItemCard = ({ item, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.put(`http://localhost:5000/api/item/${item.id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('Item updated successfully');
      onUpdate(); // Refresh the item list or details
    } catch (error) {
      message.error('Failed to update item');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="edit-item-card">
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item name="name" label="Name" initialValue={item.name} rules={[{ required: true, message: 'Please enter the item name' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" initialValue={item.description}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="price" label="Price" initialValue={item.price} rules={[{ required: true, message: 'Please enter the item price' }]}>
          <Input type="number" min={0} />
        </Form.Item>
        <Form.Item name="itemType" label="Stock" initialValue={item.itemType}>
          <Input />
        </Form.Item>
        <Form.Item name="createdAt" label="Supplier" initialValue={item.createdAt}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Update Item</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditItemCard;
