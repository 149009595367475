import React, { useState, useEffect } from 'react';
import { Card, Input, DatePicker, Button, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import './AddQuotationForm.css';

const { Option } = Select;

const AddQuotationForm = ({ onQuotationAdded }) => {
  const [customerName, setCustomerName] = useState('');
  const [orderDate, setOrderDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [items, setItems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [currentItem, setCurrentItem] = useState({ name: '', quantity: 0 });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    axios.get('https://crm.deluxecrm.co/api/item', config)
      .then(response => {
        setItemList(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the items!", error);
      });

    axios.get('https://crm.deluxecrm.co/api/customer', config)
      .then(response => {
        setCustomerList(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the customers!", error);
      });
  }, []);

  const handleAddItem = () => {
    if (currentItem.name && currentItem.quantity > 0) {
      setItems([...items, currentItem]);
      setCurrentItem({ name: '', quantity: 0 });
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem('token');

    const quotationData = {
      customer: customerName, 
      issueDate: orderDate,
      expirationDate: deliveryDate,
      items: items.map(item => ({
        item: itemList.find(i => i.name === item.name)._id, 
        quantity: item.quantity
      })),
      notes: "", 
    };

    const config = { headers: { Authorization: `Bearer ${token}` } };

    axios.post('https://crm.deluxecrm.co/api/quotations', quotationData, config)
      .then(response => {
        console.log("Quotation created successfully:", response.data);
        onQuotationAdded(); 
      })
      .catch(error => {
        console.error("There was an error creating the quotation!", error);
      });
  };

  return (
    <div className="sales-order-form">
      <div className="sales-order-cards">
        <Card className="customer-info-card" title="Billing To">
          <div className="input-group">
            <label>Customer Name</label>
            <Select
              placeholder="Select customer name"
              value={customerName}
              onChange={(value) => setCustomerName(value)}
              style={{ width: '100%' }}
            >
              {customerList.map((customer) => (
                <Option key={customer._id} value={customer._id}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="input-group">
            <label>Order Date</label>
            <DatePicker 
              style={{ width: '100%' }} 
              value={orderDate} 
              onChange={(date) => setOrderDate(date)} 
            />
          </div>

          <div className="input-group">
            <label>Expected Delivery Date</label>
            <DatePicker 
              style={{ width: '100%' }} 
              value={deliveryDate} 
              onChange={(date) => setDeliveryDate(date)} 
            />
          </div>
        </Card>

        <Card className="items-card" title="Items & Descriptions">
          <div className="item-row">
            <div className="input-group">
              <label>Item</label>
              <Select
                placeholder="Select item"
                value={currentItem.name}
                onChange={(value) => setCurrentItem({ ...currentItem, name: value })}
                style={{ width: '200px' }}
              >
                {itemList.map((item) => (
                  <Option key={item._id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-group">
              <label>Quantity</label>
              <Input 
                type="number" 
                placeholder="Enter quantity" 
                value={currentItem.quantity} 
                onChange={(e) => setCurrentItem({ ...currentItem, quantity: e.target.value })}
              />
            </div>
            <Button type="dashed" onClick={handleAddItem} className='add'>Add Item</Button>
          </div>

          <div className="added-items">
            {items.length > 0 && <h4>Added Items:</h4>}
            {items.map((item, index) => (
              <div key={index} className="added-item-row">
                <span>{item.name}</span>
                <span>Qty: {item.quantity}</span>
                <DeleteOutlined className="delete-icon" onClick={() => handleDeleteItem(index)} />
              </div>
            ))}
          </div>
        </Card>
      </div>

      <div className="form-actions">
        <Button type="primary" onClick={handleSubmit}>Submit</Button>
        <Button type="default" onClick={() => { /* handle form cancel */ }}>Cancel</Button>
      </div>
    </div>
  );
};

export default AddQuotationForm;
