import React from 'react';
import kieteeLogo from '../../assets/kieteeLogo.png'
import './ResetPassword.css'
import CustomInputField from '../../components/CustomField/CustomInputField ';
import ContinueButton from '../../components/ContinueButton/ContinueButton';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/congratulation'); 
  };

  return (
    <div className='getStarted'>
      <img src={kieteeLogo} alt="kieteeLogo.png" />
      <div className='titleGuide'>
        <span className='titleHeader'>Lets help you keep you data safe</span>
        <span className='guideText'>We’ll help you to keep your records safe and organized.</span>
      </div>
      <div className='inputsField'>
      <CustomInputField 
         label="Password" 
         name="password" 
         placeholder="Enter your password" 
         type="password"/>
      <CustomInputField 
         label="Confirm Password" 
         name="password" 
         placeholder="Enter your confirm password" 
         type="password"/>
      <ContinueButton onClick={handleClick}>Change Password</ContinueButton>
      </div>
    </div>
  )
}

export default ResetPassword