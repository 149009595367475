import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Spin, message, Input, Select, Modal } from 'antd';
import axios from 'axios';
import './DeliveryNote.css';
import Box from '../../assets/Box.png'; // Placeholder image for empty state
import { PlusOutlined } from '@ant-design/icons'; // Ant design icon
import AddDeliveryNoteForm from '../../components/AddDeliveryNoteForm/AddDeliveryNoteForm'; // Modal form for adding sales order

const { Title } = Typography;
const { Option } = Select;

const columns = [
    { title: 'ID', dataIndex: 'sequence', key: 'sequence', width: 90 },
    { title: 'Delivery Note Number', dataIndex: 'deliveryNoteNumber', key: 'deliveryNoteNumber', width: 180 },
    { title: 'Customer Name', dataIndex: 'customerName', key: 'customerName', width: 150 },
    { title: 'Order Date', dataIndex: 'orderDate', key: 'orderDate', width: 150 },
    { title: 'Status', dataIndex: 'status', key: 'status', width: 150 },
  ];
  

const DeliveryNote = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchDeliveryNoteData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://crm.deluxecrm.co/api/delivery', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const transformedOrders = response.data.map((order, index) => ({
        id: order._id,
        sequence: index + 1,
        deliveryNoteNumber: order.deliveryNoteNumber, // Ensure this is included
        customerName: order.customer.name, // Assuming `customer` has a `name` field
        orderDate: new Date(order.createdAt).toLocaleDateString(), // Format date as needed
        totalAmount: order.items.reduce((acc, item) => acc + (item.price * item.quantity), 0), // Calculate total amount
        status: order.status,
      }));
      setOrders(transformedOrders);
      setFilteredOrders(transformedOrders);
    } catch (error) {
      setError(error.message);
      message.error('Failed to fetch delivery note data');
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchDeliveryNoteData();
  }, []);

  // Handle search functionality
  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = orders.filter((order) =>
      order.customerName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  // Handle filter functionality
  const handleFilter = (value) => {
    setFilterStatus(value);
    const filtered = value ? orders.filter((order) => order.status === value) : orders;
    setFilteredOrders(filtered);
  };

  // Show modal for adding a sales order
  const handleAddData = () => {
    setIsModalVisible(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleDeliveryNoteAdded = () => {
    fetchDeliveryNoteData(); // Refresh the order list after adding
    handleCloseModal(); // Close the modal after adding
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="sales-order-container">
      <div className="header-action">
        <Title level={3} className="sales-order-title">Delivery Note</Title>
        {orders.length > 0 && (
          <div className="add-container">
            <Button className="export-button" type="secondary" onClick={() => console.log('Export CSV')}>Export CSV</Button>
            <Button className="add-button" type="primary" icon={<PlusOutlined />} onClick={handleAddData}>Add Delivery Note</Button>
          </div>
        )}
      </div>

      {orders.length === 0 ? (
        <div className="empty-field">
          <img src={Box} alt="empty box" className="box" />
          <span className="upper-text">It looks like you don’t have any Delivery Note</span>
          <span className="lower-text">Create a new one to get started</span>
          <Button type="primary" onClick={handleAddData}>Add Delivery Note</Button>
        </div>
      ) : (
        <div className="table-container">
          <div className="search-filter-container">
            <Input
              placeholder="Search by customer name"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 200 }}
            />
            <span className="filter">Filter:</span>
            <Select
              placeholder="Filter by status"
              value={filterStatus}
              onChange={handleFilter}
              style={{ width: 150 }}
              allowClear
            >
              <Option value="Pending">Pending</Option>
              <Option value="Completed">Completed</Option>
              <Option value="Cancelled">Cancelled</Option>
            </Select>
          </div>

          <Table
            columns={columns}
            dataSource={filteredOrders}
            pagination={{ pageSize: 10 }}
            rowKey="id"
            className="sales-order-table"
          />
        </div>
      )}

      {/* Add Sales Order Modal */}
      <Modal
        title="Add Delivery Note"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
        width={800}
      >
        <AddDeliveryNoteForm onDeliveryNoteAdded={handleDeliveryNoteAdded} />
      </Modal>
    </div>
  );
};

export default DeliveryNote;
