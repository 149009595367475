import React, { useState } from 'react';
import { Modal, Input, Form, Button, message, Row, Col, Select } from 'antd';
import axios from 'axios';
import './AddCustomerForm.css';

const { Option } = Select;

const AddCustomerForm = ({ visible, onClose, onCustomerAdded }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleAddCustomer = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://crm.deluxecrm.co/api/customer', values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('Customer added successfully');
      form.resetFields();
      onCustomerAdded(); // Callback to refresh the customer list
      onClose();
    } catch (error) {
      console.error('Error adding customer:', error.response?.data || error.message);
      message.error('Failed to add customer');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add Customer"
      onCancel={onClose}
      footer={null}
      className="add-customer-modal"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddCustomer}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter customer name' }]}
            >
              <Input placeholder="Customer Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please enter customer email' }]}
            >
              <Input placeholder="Customer Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: 'Please enter customer phone number' }]}
            >
              <Input placeholder="Customer Phone" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please enter customer address' }]}
            >
              <Input placeholder="Customer Address" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="companyName"
              label="Company Name"
            >
              <Input placeholder="Company Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type"
              label="Customer Type"
              rules={[{ required: true, message: 'Please select customer type' }]}
            >
              <Select placeholder="Select Type">
                <Option value="Individual">Individual</Option>
                <Option value="Business">Business</Option>
                <Option value="Industry">Industry</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="customerTIN"
              label="Customer TIN"
            >
              <Input placeholder="Customer TIN" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="customerVRN"
              label="Customer VRN"
            >
              <Input placeholder="Customer VRN" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="taxType"
              label="Tax Type"
              rules={[{ required: true, message: 'Please select tax type' }]}
            >
              <Select placeholder="Select Tax Type">
                <Option value="VAT Inclusive">VAT Inclusive</Option>
                <Option value="VAT Not Included">VAT Not Included</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" loading={loading}>
          Add Customer
        </Button>
      </Form>
    </Modal>
  );
};

export default AddCustomerForm;
