import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { Card, Typography, Row, Col, Select } from 'antd'; // Ant Design imports
import './Analytics.css'; // Normal CSS file

const { Title } = Typography;
const { Option } = Select;

const Analytics = () => {
  const data = [
    { month: 'Jan', sales: 100 },
    { month: 'Feb', sales: 200 },
    { month: 'Mar', sales: 150 },
    { month: 'Apr', sales: 300 },
    { month: 'May', sales: 250 },
    { month: 'Jun', sales: 400 },
    { month: 'Jul', sales: 30 },
    { month: 'Aug', sales: 800 },
    { month: 'Sep', sales: 700 },
    { month: 'Oct', sales: 399 },
    { month: 'Nov', sales: 1000 },
    { month: 'Dec', sales: 600 }
  ];

  return (
    <Card className="analytics-container" style={{ borderRadius: '10px' }}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4} className="analytics-title">Sales Analytics</Title>
        </Col>
        <Col>
          <Select defaultValue="2024" className="year-selection" style={{ width: 120 }}>
            <Option value="2022">2022</Option>
            <Option value="2023">2023</Option>
            <Option value="2024">2024</Option>
          </Select>
        </Col>
      </Row>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorSales" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="darkblue" stopOpacity={0.5} />
                <stop offset="100%" stopColor="darkblue" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="sales" stroke="darkblue" fill="url(#colorSales)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default Analytics;
