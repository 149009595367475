import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Login from './pages/Login/Login';
import Reset from './pages/Reset/Reset';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Dashboard from './pages/Dashboard/Dashboard';
import MainLayout from './layout/MainLayout/MainLayout';
import Customers from './pages/Customers/Customers';
import Items from './pages/Inventory/Items';
import SalesOrder from './pages/SalesOrder/SalesOrder';
import Quotation from './pages/Quotation/Quotation';
import Invoice from './pages/Invoice/Invoice';
import DeliveryNote from './pages/DeliveryNote/DeliveryNote';
import Reports from './pages/Reports/Reports';
import CustomerDetails from './pages/CustomerDetails/CustomerDetails';
import ItemDetails from './pages/ItemDetails/ItemDetails';
import SaleDetails from './pages/SalesDetails/SalesDetails';
import QuotationDetails from './pages/QuotationDetails/QuotationDetails';
import InvoiceDetails from './pages/InvoiceDetails/InvoiceDetails';

import { UserProvider } from '../src/config/UserContext';
import { AuthProvider } from './config/AuthContext';
import PrivateRoute from './config/PrivateRoute';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <UserProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              
              {/* Protected routes */}
              <Route path="/dashboard" element={<PrivateRoute><MainLayout><Dashboard /></MainLayout></PrivateRoute>} />
              <Route path="/customer" element={<PrivateRoute><MainLayout><Customers /></MainLayout></PrivateRoute>} />
              <Route path="/customer-details/:id" element={<PrivateRoute><MainLayout><CustomerDetails /></MainLayout></PrivateRoute>} />
              <Route path="/items" element={<PrivateRoute><MainLayout><Items /></MainLayout></PrivateRoute>} />
              <Route path="/items-details/:id" element={<PrivateRoute><MainLayout><ItemDetails /></MainLayout></PrivateRoute>} />
              <Route path="/sales-orders" element={<PrivateRoute><MainLayout><SalesOrder /></MainLayout></PrivateRoute>} />
              <Route path="/sales-orders-details/:id" element={<PrivateRoute><MainLayout><SaleDetails /></MainLayout></PrivateRoute>} />
              <Route path="/quotations" element={<PrivateRoute><MainLayout><Quotation /></MainLayout></PrivateRoute>} />
              <Route path="/quotations-details/:id" element={<PrivateRoute><MainLayout><QuotationDetails /></MainLayout></PrivateRoute>} />
              <Route path="/invoices" element={<PrivateRoute><MainLayout><Invoice /></MainLayout></PrivateRoute>} />
              <Route path="/invoices-details/:id" element={<PrivateRoute><MainLayout><InvoiceDetails /></MainLayout></PrivateRoute>} />
              <Route path="/delivery-notes" element={<PrivateRoute><MainLayout><DeliveryNote /></MainLayout></PrivateRoute>} />
              <Route path="/reports" element={<PrivateRoute><MainLayout><Reports /></MainLayout></PrivateRoute>} />
            </Routes>
          </Router>
        </UserProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
