import React, { useState, useEffect } from 'react';
import { Card, Input, DatePicker, Button, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import './AddSalesForm.css'; // Assume you have a CSS file for sales form styles

const { Option } = Select;

const AddSalesForm = ({ onSalesOrderAdded }) => {
  const [customerId, setCustomerId] = useState('');
  const [issueDate, setIssueDate] = useState(null); // Changed to issueDate to match backend
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [items, setItems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [currentItem, setCurrentItem] = useState({ itemId: '', quantity: 0 });

  // Fetch customers and items when the component is mounted
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    axios.get('https://crm.deluxecrm.co/api/item', config)
      .then(response => {
        setItemList(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the items!", error);
      });

    axios.get('https://crm.deluxecrm.co/api/customer', config)
      .then(response => {
        setCustomerList(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the customers!", error);
      });
  }, []);

  const handleAddItem = () => {
    if (currentItem.itemId && currentItem.quantity > 0) {
      setItems([...items, currentItem]);
      setCurrentItem({ itemId: '', quantity: 0 });
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem('token');

    const salesOrderData = {
      customer: customerId, // Ensure this is the customer ID, not the name
      issueDate: issueDate, // Use issueDate as expected by backend
      deliveryDate: deliveryDate,
      items: items.map(item => ({
        item: item.itemId, // Ensure you send the item ID
        quantity: item.quantity
      })),
      notes: "", // Additional field for notes if needed
    };

    const config = { headers: { Authorization: `Bearer ${token}` } };

    axios.post('https://crm.deluxecrm.co/api/orders', salesOrderData, config)
      .then(response => {
        console.log("Sales order created successfully:", response.data);
        onSalesOrderAdded(); // Callback to refresh the sales order list or handle post-submit actions
      })
      .catch(error => {
        console.error("There was an error creating the sales order!", error);
      });
  };

  return (
    <div className="sales-order-form">
      <div className="sales-order-cards">
        <Card className="customer-info-card" title="Billing To">
          <div className="input-group">
            <label>Customer Name</label>
            <Select
              placeholder="Select customer name"
              value={customerId} // Changed to customerId to match backend
              onChange={(value) => setCustomerId(value)}
              style={{ width: '100%' }}
            >
              {customerList.map((customer) => (
                <Option key={customer._id} value={customer._id}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="input-group">
            <label>Order Date</label>
            <DatePicker 
              style={{ width: '100%' }} 
              value={issueDate} // Changed to issueDate
              onChange={(date) => setIssueDate(date)} 
            />
          </div>

          <div className="input-group">
            <label>Expected Delivery Date</label>
            <DatePicker 
              style={{ width: '100%' }} 
              value={deliveryDate} 
              onChange={(date) => setDeliveryDate(date)} 
            />
          </div>
        </Card>

        <Card className="items-card" title="Items & Descriptions">
          <div className="item-row">
            <div className="input-group">
              <label>Item</label>
              <Select
                placeholder="Select item"
                value={currentItem.itemId} // Changed to itemId
                onChange={(value) => setCurrentItem({ ...currentItem, itemId: value })}
                style={{ width: '200px' }}
              >
                {itemList.map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-group">
              <label>Quantity</label>
              <Input 
                type="number" 
                placeholder="Enter quantity" 
                value={currentItem.quantity} 
                onChange={(e) => setCurrentItem({ ...currentItem, quantity: e.target.value })} 
              />
            </div>
            <Button type="dashed" onClick={handleAddItem} className='add'>Add Item</Button>
          </div>

          <div className="added-items">
            {items.length > 0 && <h4>Added Items:</h4>}
            {items.map((item, index) => (
              <div key={index} className="added-item-row">
                <span>{itemList.find(i => i._id === item.itemId)?.name}</span> {/* Match name */}
                <span>Qty: {item.quantity}</span>
                <DeleteOutlined className="delete-icon" onClick={() => handleDeleteItem(index)} />
              </div>
            ))}
          </div>
        </Card>
      </div>

      <div className="form-actions">
        <Button type="primary" onClick={handleSubmit}>Submit</Button>
        <Button type="default" onClick={() => { /* handle form cancel */ }}>Cancel</Button>
      </div>
    </div>
  );
};

export default AddSalesForm;
