
import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './CustomerDetails.css';
import CustomerCard from '../../components/CustomerCard/CustomerCard';
import EditCustomerCard from '../../components/EditCustomerCard/EditCustomerCard';

const { Title } = Typography;

export default function CustomerDetails() {
  const { id } = useParams(); // get the customer ID from the URL
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://crm.deluxecrm.co/api/customer/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCustomer(response.data);
      } catch (error) {
        console.error('Failed to fetch customer details', error);
      }
    };

    fetchCustomerDetails();
  }, [id]); // Only run when the 'id' changes

  if (!customer) {
    return <div>Loading...</div>;
  }

  return (
    <div className='customer-details-container'>
      <div className="header-action">
        <Title level={3} className="customer-title">Customers Details</Title>
      </div>
      <div className='component-container'>
        <CustomerCard customer={customer} />
        <EditCustomerCard customer={customer} />
      </div>
    </div>
  );
}