import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ItemDetails.css';
import ItemCard from '../../components/ItemCard/ItemCard'; // Updated import
import EditItemCard from '../../components/EditItemCard/EditItemCard'; // Updated import

const { Title } = Typography;

function ItemDetails() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://crm.deluxecrm.co/api/item/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setItem(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [id]);

  const handleUpdate = () => {
    // Optionally, refresh the item details or handle other logic
    setItem(prevItem => ({ ...prevItem })); // Refresh the item details
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='item-details-container'>
      <div className="header-action">
        <Title level={3} className="item-title">Item Details</Title>
      </div>
      <div className='component-container'>
        <ItemCard item={item} />
        <EditItemCard item={item} onUpdate={handleUpdate} />
      </div>
    </div>
  );
}

export default ItemDetails;
