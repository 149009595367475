import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Spin, message, Input, Select } from 'antd'; // Ant Design imports
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Customers.css'; // Custom styling
import Box from '../../assets/Box.png'; // Placeholder image for empty state
import AddDataButton from '../../components/AddDataButton/AddDataButton'; // Add button component
import { PlusOutlined } from '@ant-design/icons'; // Importing the plus icon
import AddCustomerForm from '../../components/AddCustomerForm/AddCustomerForm';

const { Title } = Typography;
const { Option } = Select;

const columns = [
  { title: 'ID', dataIndex: 'sequence', key: 'sequence', width: 90 },
  { title: 'Name', dataIndex: 'name', key: 'name', width: 150 },
  { title: 'Email', dataIndex: 'email', key: 'email', width: 200 },
  { title: 'Phone', dataIndex: 'phone', key: 'phone', width: 150 },
  { title: 'Address', dataIndex: 'address', key: 'address', width: 200 },
  { title: 'Company Name', dataIndex: 'companyName', key: 'companyName', width: 200 },
  { title: 'Type', dataIndex: 'type', key: 'type', width: 150 },
  { title: 'Total Purchases', dataIndex: 'totalPurchases', key: 'totalPurchases', width: 150 },
];

const Customers = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const fetchCustomerData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://crm.deluxecrm.co/api/customer', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const transformedRows = response.data.map((item, index) => ({
        id: item._id,
        sequence: index + 1,
        ...item,
      }));
      setRows(transformedRows);
      setFilteredRows(transformedRows);
    } catch (error) {
      setError(error.message);
      message.error('Failed to fetch customer data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  // Handle search functionality
  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = rows.filter((row) =>
      row.name.toLowerCase().includes(value.toLowerCase()) ||
      row.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRows(filtered);
  };

  // Handle filter functionality
  const handleFilter = (value) => {
    setFilterType(value);
    const filtered = value
      ? rows.filter((row) => row.type === value)
      : rows;
    setFilteredRows(filtered);
  };

  // Show modal for adding a customer
  const handleAddData = () => {
    setIsModalVisible(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleCustomerAdded = () => {
    fetchCustomerData(); // Refresh the customer list after adding
  };

  // Handle row click
  const handleRowClick = (record) => {
    navigate(`/customer-details/${record.id}`); // Navigate to CustomerDetails page
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="customer-container">
      <div className="header-action">
        <Title level={3} className="customer-title">Customers</Title>
        {rows.length > 0 && (
          <div className="add-container">
            <Button className="export-button" type="secondary" onClick={handleAddData}>Export CSV</Button>
            <Button className="add-button" type="primary" icon={<PlusOutlined />} onClick={handleAddData}>Add Customer</Button>
          </div>
        )}
      </div>

      {rows.length === 0 ? (
        <div className="empty-field">
          <img src={Box} alt="empty box" className="box" />
          <span className="upper-text">It looks like you don’t have any data</span>
          <span className="lower-text">Fortunately, it’s easy to create a new one</span>
          <AddDataButton onClick={handleAddData} className="add-button" />
        </div>
      ) : (
        <div className="table-container">
          <div className="search-filter-container">
            <Input
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 200 }}
            />
            <span className='filter'>filter:</span>
            <Select
              placeholder="Filter by type"
              value={filterType}
              onChange={handleFilter}
              style={{ width: 150 }}
              allowClear
            >
              <Option value="Individual">Individual</Option>
              <Option value="Business">Business</Option>
              <Option value="Industry">Industry</Option>
            </Select>
          </div>

          <Table
  columns={columns}
  dataSource={filteredRows}
  pagination={{ pageSize: 10 }}
  rowKey="id"
  className="customer-table" // Ensure the correct class is applied
  onRow={(record) => ({
    onClick: () => handleRowClick(record), // Handle row click if needed
  })}
/>

        </div>
      )}

      {/* Add Customer Modal */}
      <AddCustomerForm   
        visible={isModalVisible} 
        onClose={handleCloseModal} 
        onCustomerAdded={handleCustomerAdded}
      />
    </div>
  );
};

export default Customers;
