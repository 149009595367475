import React, { useState, useEffect } from 'react';
import { Input, Select, Button, notification } from 'antd';
import './EditCustomerCard.css';

const { Option } = Select;

function EditCustomerCard({ customer, onUpdate }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    tin: '',
    taxType: '',
    type: ''
  });

  useEffect(() => {
    if (customer) {
      setFormData({
        name: customer.name || '',
        email: customer.email || '',
        phone: customer.phone || '',
        address: customer.address || '',
        tin: customer.tin || '',
        taxType: customer.taxType || '',
        type: customer.type || ''
      });
    }
  }, [customer]);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!customer || !customer._id) {
      notification.error({ message: 'Customer ID is missing. Please try again.' });
      return;
    }

    try {
      const response = await fetch(`http://localhost:5000/api/customer/${customer._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const updatedCustomer = await response.json();
        notification.success({ message: 'Customer updated successfully!' });
        onUpdate && onUpdate(updatedCustomer);
      } else {
        const errorData = await response.json();
        notification.error({ message: `Error: ${errorData.message}` });
      }
    } catch (error) {
      notification.error({ message: 'An unexpected error occurred.' });
    }
  };

  return (
    <div className='edit-card-container'>
      <h3 className='form-header'>Edit Customer Details</h3>
      <form onSubmit={handleSubmit} className="edit-form">
        <div className='row-format'>
          <div className='form-group'>
            <label className='form-label'>Name</label>
            <Input
              type='text'
              placeholder='Enter customer name'
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              className='form-control'
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Email</label>
            <Input
              type='email'
              placeholder='Enter customer email'
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              className='form-control'
            />
          </div>
        </div>
        <div className='row-format'>
          <div className='form-group'>
            <label className='form-label'>Phone</label>
            <Input
              type='tel'
              placeholder='Enter customer phone'
              value={formData.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
              className='form-control'
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Address</label>
            <Input
              type='text'
              placeholder='Enter customer address'
              value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)}
              className='form-control'
            />
          </div>
        </div>
        <div className='row-format'>
          <div className='form-group'>
            <label className='form-label'>TIN</label>
            <Input
              type='text'
              placeholder='Enter customer TIN'
              value={formData.tin}
              onChange={(e) => handleChange('tin', e.target.value)}
              className='form-control'
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Tax Type</label>
            <Select
              placeholder='Select Tax Type'
              value={formData.taxType}
              onChange={(value) => handleChange('taxType', value)}
              className='form-control'
            >
              <Option value='VAT Inclusive'>VAT Inclusive</Option>
              <Option value='VAT Not Included'>VAT Not Included</Option>
            </Select>
          </div>
        </div>
        <div className='form-group'>
          <label className='form-label'>Type</label>
          <Select
            placeholder='Select Type'
            value={formData.type}
            onChange={(value) => handleChange('type', value)}
            className='form-control'
          >
            <Option value='Individual'>Individual</Option>
            <Option value='Business'>Business</Option>
            <Option value='Industry'>Industry</Option>
          </Select>
        </div>
        <Button type='primary' htmlType='submit' className='update-button'>
          Update
        </Button>
      </form>
    </div>
  );
}

export default EditCustomerCard;
